import axios from "axios";
import { STOCKAPI, STOCKUPDATEAPI } from "../utils/api";
import { BASE_AUTH } from "../utils/base_auth";
import { getToken } from "../utils/storage";

export function stockItems() {
    const data = {
        farmId:"611a5a5d55c5cb38895fce12"
    }
    return axios.post(STOCKAPI, data, {
      headers: {
        Authorization: BASE_AUTH,
        "Content-Type": "application/json",
        Token:getToken()
      },
    });
  }

  export function updateStock(data){
    return axios.patch(STOCKUPDATEAPI, data, {
        headers: {
          Authorization: BASE_AUTH,
          "Content-Type": "application/json",
          Token:getToken()
        },
      });
  }