import React, { useEffect, useState } from "react";
import { stockItems, updateStock } from "../../service/stock";

const StockScreen = () => {
  const [stock, setStock] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [onlineSearch, setOnlineSearch] = useState('')
  const [codeSearch, setcodeSearch] = useState('')

  useEffect(() => {
    stockItems().then((res) => {
      setStock(res.data.result);
      setStockList(res.data.result);
    });
  }, []);

  const updateTheStock = (value, e) => {
    const index = stock.findIndex((res) => res._id === value._id);
    const updatedstocks = [...stock];

    updatedstocks[index][e.target.name] = e.target.value
    updatedstocks[index][`${e.target.name}color`] = "lightgreen";


    const data = { ...value, [e.target.name]: e.target.value };
    updateStock(data).then((res) => {
      if (res.data.success) {
        console.log(res.data.value)
        if(onlineSearch.length>0){
            const value = updatedstocks.filter((value) =>
                value.farmProductId?.productName?.toLowerCase().includes(onlineSearch.toLowerCase())
              )
            setStockList(value)
        }

       else  if(codeSearch.length>0){
            if(stock.length>0){
                const value = stock.filter((value) =>
                  value.farmProductId?.farmProductCode?.includes(codeSearch)
                )
                console.log(value)
                setStockList(value)
              }
        }
        else{
          setStockList(updatedstocks)
        }
        
        
      }
    });
  };
  useEffect(()=>{
    if(stock.length>0){
      const value = stock.filter((value) =>
        value.farmProductId?.productName?.toLowerCase().includes(onlineSearch.toLowerCase())
      )
      console.log(value)
      setStockList(value)
    }
  },[onlineSearch])

  useEffect(()=>{
    if(stock.length>0){
      const value = stock.filter((value) =>
        value.farmProductId?.farmProductCode?.includes(codeSearch)
      )
      console.log(value)
      setStockList(value)
    }
  },[codeSearch])


  const newUpdateTheStockAll=(value)=>{
    const index = stock.findIndex((res) => res._id === value._id);
    const updatedstocks = [...stock];

    updatedstocks[index]["unit"] = 10
    updatedstocks[index][`unitcolor`] = "lightgreen";


    const data = { ...value, ["unit"]: 10 };
    updateStock(data).then((res) => {
      if (res.data.success) {
        console.log(res.data.value)

        setStockList(updatedstocks)
        // if(onlineSearch.length>0){
        //     const value = updatedstocks.filter((value) =>
        //         value.farmProductId?.productName?.toLowerCase().includes(onlineSearch.toLowerCase())
        //       )
        //     setStockList(value)
        // }

        // if(codeSearch.length>0){
        //     if(stock.length>0){
        //         const value = stock.filter((value) =>
        //           value.farmProductId?.farmProductCode?.includes(codeSearch)
        //         )
        //         console.log(value)
        //         setStockList(value)
        //       }
        // }
        
        
      }
    });
  }

  const updateAll = () =>{
    stock.map((res)=>{
      newUpdateTheStockAll(res)
    })
  }

  return (
    <div>
      {/* <input type="button" value="Change" onClick={updateAll} /> */}
        <input type="text" className="retailSearch" value={onlineSearch} placeholder="Search Product" onChange={(e)=>setOnlineSearch(e.target.value)}/>
        <input type="text" className="retailSearch" value={codeSearch} placeholder="Search Product by code" onChange={(e)=>setcodeSearch(e.target.value)}/>
      <table>
        <thead>
          <tr>
            <th>Index</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Unit Value</th>
          </tr>
        </thead>
        <tbody>
          {stockList &&
            stockList.map((res,index) => {
              return (
                <tr>
                    <th><h3>{index}</h3></th>
                  <th>
                    <h5>{res.farmProductId? res.farmProductId.productName:''}----{res.farmProductId?.farmProductCode}</h5>
                  </th>
                  <th>
                    <input
                      type="text"
                      style={{
                        backgroundColor: res.unitcolor || "",
                      }}
                      name="unit"
                      id="unit"
                      value={res.unit}
                      onChange={(e) => updateTheStock(res, e)}
                    />
                  </th>
                  <th>{res.unitValue}</th>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default StockScreen;
