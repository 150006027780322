// export const BASE_URL = "http://localhost:3001/api/v1/";
export const BASE_URL = "https://f2b.farm2bag.com/api/v1/"

export const PRODUCTSAPI = BASE_URL + "b2c/item";
export const CATEGORYAPI = BASE_URL + "b2c/category";
export const CATEGORYMASTERAPI = BASE_URL + "productType/active"
export const PRODUCTTYPEAPI = BASE_URL + "productType/getAll"
export const FARMERAPI = BASE_URL + "farmer/active"
export const UNITAPI = BASE_URL + "unit/active"
export const ORDERAPI = BASE_URL + "order/retailshop";

export const UPDATEFARMPRODUCTAPI = BASE_URL + "farmProduct/newUpdate"
export const ADDMULTIPLEFARMPRODUCTAPI = BASE_URL + "farmproduct/"
export const GETFARMPRODUCTBYIDAPI = BASE_URL + "farmproduct/getAll"


export const LOGINAPI = BASE_URL + "login/admin"

export const STOCKAPI = BASE_URL + "stock/getStockByFarm"
export const STOCKUPDATEAPI = BASE_URL + "stock/updateStock"


export const BASE_URL1 = "https://pos.farm2bag.com/api/v1/"
export const RETAILCATEGORYAPI = BASE_URL1 + 'categories'
export const RETAILPRODUCTDISPLAY = BASE_URL1 + 'stocks/billingPageWarehouse/6672e725708b954538429b62'